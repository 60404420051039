import React, { useState } from 'react';
import { X } from 'lucide-react';
import { toast } from 'react-toastify';

interface LeadMagnetPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const popupContent = {
  title: "Download Your Free MRI Patient Guide",
  description: "Learn everything you need to know about MRI scans, including preparation instructions and what to expect during your visit.",
  buttonText: "Get Your Free Guide",
  benefits: [
    "Complete MRI preparation checklist",
    "What to expect during your scan",
    "Understanding MRI safety guidelines",
    "Post-scan care instructions"
  ]
};

export const LeadMagnetPopup: React.FC<LeadMagnetPopupProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Handle form submission logic here
      console.log('Form submitted:', { name, email });
      
      // Show success message
      toast.success('Success! Check your email for the MRI Patient Guide.');
      
      // Close popup
      onClose();
      
      // Reset form
      setName('');
      setEmail('');
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            {popupContent.title}
          </h2>
          
          <p className="text-gray-600 mb-6">
            {popupContent.description}
          </p>

          <div className="mb-6">
            <h3 className="font-semibold text-gray-900 mb-3">What's Inside:</h3>
            <ul className="space-y-2">
              {popupContent.benefits.map((benefit, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-300 disabled:opacity-50"
            >
              {loading ? 'Sending...' : popupContent.buttonText}
            </button>
          </form>

          <p className="text-xs text-gray-500 mt-4 text-center">
            We respect your privacy. Your information is safe and will never be shared.
          </p>
        </div>
      </div>
    </div>
  );
};