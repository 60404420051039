import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Phone } from 'lucide-react';
import mriLogo from '../assets/mri-logo.png';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Services', href: '/services/brain-mri' },
  { name: 'Book Appointment', href: '/appointment' },
  { name: 'Contact', href: '/contact' },
];

interface NavbarProps {
  onLeadMagnetClick: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ onLeadMagnetClick }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white border-b border-gray-200">
      <nav className="flex items-center justify-between p-4 lg:px-8 max-w-7xl mx-auto" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Advanced Medical Imaging Center</span>
            <img 
              src={mriLogo} 
              alt="MRI Center Logo" 
              className="h-20 w-auto object-contain"
            />
          </Link>
        </div>
        
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-gray-900"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        <div className="hidden lg:flex lg:gap-x-6">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-medium tracking-wide text-gray-600 hover:text-blue-600 transition-colors duration-200 relative group"
            >
              {item.name}
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
            </Link>
          ))}
        </div>
        
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-6">
          <a
            href="tel:+919876543210"
            className="text-sm font-medium tracking-wide text-gray-600 hover:text-blue-600 flex items-center transition-colors duration-200"
          >
            <Phone className="h-4 w-4 mr-2" />
            +91 98765 43210
          </a>
          <button
            onClick={onLeadMagnetClick}
            className="text-sm font-medium px-5 py-2.5 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200 shadow-sm"
          >
            Patient Guide
          </button>
        </div>
      </nav>
      
      {/* Mobile menu */}
      {mobileMenuOpen && (
        <div className="lg:hidden">
          <div className="fixed inset-0 z-50 bg-black/20 backdrop-blur-sm" onClick={() => setMobileMenuOpen(false)} />
          <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white/100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Advanced Medical Imaging Center</span>
                <img 
                  src={mriLogo} 
                  alt="MRI Center Logo" 
                  className="h-16 w-auto object-contain"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:text-gray-900"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-200">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="block px-4 py-3 text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="tel:+919876543210"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    <div className="flex items-center">
                      <Phone className="h-5 w-5 mr-2" />
                      +91 98765 43210
                    </div>
                  </a>
                  <button
                    onClick={() => {
                      onLeadMagnetClick();
                      setMobileMenuOpen(false);
                    }}
                    className="mt-4 w-full rounded-lg bg-blue-600 px-3 py-2.5 text-base font-medium text-white hover:bg-blue-700"
                  >
                    Patient Guide
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};