import React from 'react';
import { Quote } from 'lucide-react';

export const TestimonialSection = () => {
  const testimonials = [
    {
      quote: "The MRI team was incredibly professional and made me feel at ease throughout the entire process. The results were quick and comprehensive.",
      author: "Sarah Johnson",
      title: "Patient",
      image: "https://randomuser.me/api/portraits/women/1.jpg"
    },
    {
      quote: "As a referring physician, I consistently rely on their expertise. The detailed imaging and quick turnaround time help me provide better care for my patients.",
      author: "Dr. Michael Chen",
      title: "Neurologist",
      image: "https://randomuser.me/api/portraits/men/2.jpg"
    },
    {
      quote: "The facility is state-of-the-art and the staff is exceptional. They took extra care to ensure my comfort during my cardiac MRI.",
      author: "Robert Williams",
      title: "Cardiac Patient",
      image: "https://randomuser.me/api/portraits/men/3.jpg"
    }
  ];

  return (
    <section className="relative py-20 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="text-gray-900">What Our</span>{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
              Patients Say
            </span>
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Read about experiences from our patients and healthcare partners
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="group relative bg-white/80 backdrop-blur-lg p-8 rounded-2xl shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300"
            >
              {/* Gradient Blob */}
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
              
              {/* Quote Icon */}
              <div className="absolute -top-4 -right-4 w-8 h-8 bg-gradient-to-br from-blue-600 to-blue-400 rounded-full flex items-center justify-center shadow-lg">
                <Quote className="w-4 h-4 text-white" />
              </div>
              
              <div className="relative">
                <div className="flex items-center mb-6">
                  <div className="relative">
                    <img
                      src={testimonial.image}
                      alt={testimonial.author}
                      className="w-14 h-14 rounded-full object-cover"
                    />
                    <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-600 to-blue-400 opacity-0 group-hover:opacity-20 transition-opacity" />
                  </div>
                  <div className="ml-4">
                    <div className="font-semibold text-gray-900">{testimonial.author}</div>
                    <div className="text-blue-600 text-sm">{testimonial.title}</div>
                  </div>
                </div>
                <blockquote className="text-gray-600 leading-relaxed italic">
                  "{testimonial.quote}"
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
