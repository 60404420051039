import React from 'react';
import { Users, Clock, Award, Activity } from 'lucide-react';

export const StatsSection = () => {
  const stats = [
    {
      value: '15k+',
      label: 'Annual Scans',
      icon: Activity,
      description: 'Successful imaging procedures'
    },
    {
      value: '99%',
      label: 'Accuracy Rate',
      icon: Award,
      description: 'Diagnostic precision'
    },
    {
      value: '24h',
      label: 'Result Time',
      icon: Clock,
      description: 'Fast turnaround'
    },
    {
      value: '10+',
      label: 'Expert Radiologists',
      icon: Users,
      description: 'Specialized doctors'
    }
  ];

  return (
    <section className="relative py-20 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900" />
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div 
                key={index} 
                className="group relative bg-white/10 backdrop-blur-sm rounded-2xl p-6 border border-white/20"
              >
                {/* Hover Effect */}
                <div className="absolute -inset-1 bg-gradient-to-r from-blue-400 to-blue-300 rounded-2xl opacity-0 group-hover:opacity-20 blur transition-opacity" />
                
                <div className="relative">
                  <div className="w-12 h-12 bg-white/20 rounded-xl flex items-center justify-center mb-4">
                    <Icon className="w-6 h-6 text-blue-200" />
                  </div>
                  <div className="text-4xl font-bold text-white mb-2">{stat.value}</div>
                  <div className="text-blue-200 font-medium mb-1">{stat.label}</div>
                  <div className="text-blue-300 text-sm">{stat.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};