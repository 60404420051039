import React from 'react';
import { CTAButton } from './CTAButton';
import { Calendar, FileText, ArrowRight, Phone, Clock, Shield } from 'lucide-react';

interface CTASectionProps {
  onLeadMagnetClick: () => void;
}

export const CTASection = ({ onLeadMagnetClick }: CTASectionProps) => {
  const benefits = [
    {
      icon: Clock,
      title: 'Quick Scheduling',
      description: 'Book your scan at your convenience'
    },
    {
      icon: Shield,
      title: 'Expert Care',
      description: 'Board-certified radiologists'
    },
    {
      icon: Phone,
      title: '24/7 Support',
      description: 'Always here to help you'
    }
  ];

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900" />
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-10" />
      
      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column */}
          <div>
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-white">Ready for Your</span>{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-200 to-blue-400">
                MRI Scan?
              </span>
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Schedule your appointment today and experience our state-of-the-art facility with expert care at affordable prices.
            </p>

            {/* Benefits Grid */}
            <div className="grid sm:grid-cols-3 gap-6 mb-12">
              {benefits.map((benefit, index) => (
                <div 
                  key={index}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-4 border border-blue-400/20"
                >
                  <div className="w-10 h-10 bg-blue-400/20 rounded-lg flex items-center justify-center mb-3">
                    <benefit.icon className="w-5 h-5 text-blue-200" />
                  </div>
                  <h3 className="text-white font-semibold mb-1">{benefit.title}</h3>
                  <p className="text-blue-200 text-sm">{benefit.description}</p>
                </div>
              ))}
            </div>

            {/* CTA Buttons */}
            <div className="flex flex-col sm:flex-row items-center gap-6">
              <CTAButton 
                text="Schedule Appointment" 
                className="text-lg px-8 py-4 bg-gradient-to-r from-blue-500 to-blue-400 hover:from-blue-600 hover:to-blue-500 shadow-lg shadow-blue-500/20"
              />
              <button
                onClick={onLeadMagnetClick}
                className="text-blue-200 hover:text-white flex items-center gap-2 group"
              >
                <FileText className="w-5 h-5" />
                Download Patient Guide
                <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>

          {/* Right Column - Contact Card */}
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 border border-blue-400/20">
            <div className="bg-gradient-to-br from-blue-400/20 to-transparent rounded-xl p-6 mb-6">
              <Calendar className="w-12 h-12 text-blue-200 mb-4" />
              <h3 className="text-2xl font-bold text-white mb-2">Quick Consultation</h3>
              <p className="text-blue-200">
                Get a free consultation with our experts to discuss your MRI requirements and pricing options.
              </p>
            </div>

            {/* Contact Form */}
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full px-4 py-3 bg-white/5 border border-blue-400/20 rounded-lg text-white placeholder-blue-200 focus:outline-none focus:border-blue-400"
              />
              <input
                type="tel"
                placeholder="Phone Number"
                className="w-full px-4 py-3 bg-white/5 border border-blue-400/20 rounded-lg text-white placeholder-blue-200 focus:outline-none focus:border-blue-400"
              />
              <select className="w-full px-4 py-3 bg-white/5 border border-blue-400/20 rounded-lg text-blue-200 focus:outline-none focus:border-blue-400">
                <option value="">Select MRI Type</option>
                <option value="brain">Brain MRI</option>
                <option value="cardiac">Cardiac MRI</option>
                <option value="full-body">Full Body MRI</option>
              </select>
              <button className="w-full py-4 bg-gradient-to-r from-blue-500 to-blue-400 hover:from-blue-600 hover:to-blue-500 text-white rounded-lg font-semibold shadow-lg shadow-blue-500/20">
                Request Callback
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};