import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Brain, Activity, Stethoscope, ChevronRight, Shield, Clock, FileText } from 'lucide-react';

const services = [
  {
    icon: Brain,
    title: 'Brain MRI',
    description: 'Advanced neurological imaging for precise diagnosis of brain conditions, tumors, and disorders.',
    features: [
      '3D Brain Mapping',
      'Tumor Detection',
      'Stroke Assessment',
      'Detailed Neurological Analysis'
    ],
    price: '₹4,999 onwards',
    duration: '45-60 minutes',
    preparation: [
      'Remove metal objects',
      'No special fasting required',
      'Wear comfortable clothing'
    ]
  },
  {
    icon: Activity,
    title: 'Full Body MRI',
    description: 'Comprehensive full-body scanning for thorough health assessment and preventive care.',
    features: [
      'Complete Health Screening',
      'Early Disease Detection',
      'Detailed Organ Analysis',
      'Comprehensive Report'
    ],
    price: '₹14,999 onwards',
    duration: '90-120 minutes',
    preparation: [
      'Fasting may be required',
      'Wear loose, comfortable clothing',
      'Bring previous medical records'
    ]
  },
  {
    icon: Stethoscope,
    title: 'Cardiac MRI',
    description: 'Specialized cardiac imaging for detailed heart structure and function analysis.',
    features: [
      'Heart Function Analysis',
      'Blood Flow Study',
      'Structural Assessment',
      'Coronary Artery Evaluation'
    ],
    price: '₹6,999 onwards',
    duration: '60-90 minutes',
    preparation: [
      'No caffeine 24 hours before',
      'Wear comfortable clothing',
      'Bring list of current medications'
    ]
  }
];

export const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>MRI Services | Advanced Medical Imaging Center</title>
        <meta 
          name="description" 
          content="Comprehensive MRI services including Brain, Full Body, and Cardiac MRI scans. State-of-the-art diagnostic imaging with expert interpretation." 
        />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
        
        <div className="relative pt-48 pb-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* Header Section */}
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="text-gray-900">Our Advanced</span>{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
                  MRI Services
                </span>
              </h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                Cutting-edge medical imaging technology for precise diagnostics and comprehensive health assessment
              </p>
            </div>

            {/* Services Grid */}
            <div className="grid md:grid-cols-3 gap-8">
              {services.map((service) => (
                <div 
                  key={service.title} 
                  className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300"
                >
                  {/* Gradient Blob */}
                  <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
                  
                  <div className="relative">
                    {/* Icon */}
                    <div className="w-16 h-16 bg-gradient-to-br from-blue-600 to-blue-400 rounded-2xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                      <service.icon className="w-8 h-8 text-white" />
                    </div>

                    {/* Service Details */}
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">{service.title}</h2>
                    <p className="text-gray-600 mb-6">{service.description}</p>

                    {/* Features */}
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold text-gray-800 mb-3">Key Features</h3>
                      <ul className="space-y-2">
                        {service.features.map((feature, idx) => (
                          <li key={idx} className="flex items-center text-gray-600">
                            <div className="w-2 h-2 rounded-full bg-blue-600 mr-3" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Preparation */}
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold text-gray-800 mb-3">Preparation</h3>
                      <ul className="space-y-2">
                        {service.preparation.map((prep, idx) => (
                          <li key={idx} className="flex items-center text-gray-600">
                            <Shield className="w-4 h-4 text-blue-600 mr-3" />
                            {prep}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Service Metadata */}
                    <div className="grid grid-cols-2 gap-4 mb-6">
                      <div className="bg-blue-50 rounded-lg p-3 text-center">
                        <div className="flex items-center justify-center mb-2">
                          <Clock className="w-5 h-5 text-blue-600 mr-2" />
                          <span className="text-sm font-medium text-gray-700">Duration</span>
                        </div>
                        <div className="text-blue-600 font-semibold">{service.duration}</div>
                      </div>
                      <div className="bg-blue-50 rounded-lg p-3 text-center">
                        <div className="flex items-center justify-center mb-2">
                          <FileText className="w-5 h-5 text-blue-600 mr-2" />
                          <span className="text-sm font-medium text-gray-700">Price</span>
                        </div>
                        <div className="text-blue-600 font-semibold">{service.price}</div>
                      </div>
                    </div>

                    {/* CTA */}
                    <div className="flex justify-between items-center">
                      <a
                        href="/appointment"
                        className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-300"
                      >
                        Book Now
                        <ChevronRight className="ml-2 w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
