import React from 'react';
import { 
  Phone, 
  Mail, 
  MapPin, 
  Clock, 
  Facebook, 
  Instagram, 
  Linkedin,
  Calendar,
  FileText,
  Shield,
  Star 
} from 'lucide-react';
import mriLogo from '/src/assets/mriscan-logo-transparent.png';

export const Footer = () => {
  return (
    <footer className="relative overflow-hidden bg-gradient-to-b from-gray-900 to-blue-900">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
      
      <div className="relative pt-20 pb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Main Footer Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-16">
            {/* Company Info */}
            <div>
              <div className="mb-6">
                <img 
                  src={mriLogo} 
                  alt="MRI Care Logo" 
                  className="h-20 w-auto"
                />
              </div>
              <p className="text-blue-200 mb-6">
                State-of-the-art diagnostic imaging center providing comprehensive MRI services with expert care.
              </p>
              <div className="space-y-4">
                <a href="tel:+919876543210" className="flex items-center text-blue-200 hover:text-white transition-colors">
                  <Phone className="w-5 h-5 mr-3" />
                  +91 98765 43210
                </a>
                <a href="mailto:care@mricare.com" className="flex items-center text-blue-200 hover:text-white transition-colors">
                  <Mail className="w-5 h-5 mr-3" />
                  care@mricare.com
                </a>
                <div className="flex items-start text-blue-200">
                  <MapPin className="w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <span>123 Medical Center Road,<br />Mumbai, Maharashtra 400001</span>
                </div>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-6">Services</h3>
              <ul className="space-y-4">
                <li>
                  <a href="/services/brain-mri" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-400 mr-3" />
                    Brain MRI
                  </a>
                </li>
                <li>
                  <a href="/services/cardiac-mri" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-400 mr-3" />
                    Cardiac MRI
                  </a>
                </li>
                <li>
                  <a href="/services/full-body-mri" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-400 mr-3" />
                    Full Body MRI
                  </a>
                </li>
                <li>
                  <a href="/pricing" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-400 mr-3" />
                    Pricing
                  </a>
                </li>
              </ul>
            </div>

            {/* Patient Resources */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-6">Patient Resources</h3>
              <ul className="space-y-4">
                <li>
                  <a href="/patient-guide" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <FileText className="w-4 h-4 mr-3" />
                    Patient Guide
                  </a>
                </li>
                <li>
                  <a href="/book-appointment" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <Calendar className="w-4 h-4 mr-3" />
                    Book Appointment
                  </a>
                </li>
                <li>
                  <a href="/insurance" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <Shield className="w-4 h-4 mr-3" />
                    Insurance Partners
                  </a>
                </li>
                <li>
                  <a href="/testimonials" className="text-blue-200 hover:text-white transition-colors flex items-center">
                    <Star className="w-4 h-4 mr-3" />
                    Patient Stories
                  </a>
                </li>
              </ul>
            </div>

            {/* Working Hours */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-6">Working Hours</h3>
              <ul className="space-y-4">
                <li className="flex items-center text-blue-200">
                  <Clock className="w-4 h-4 mr-3" />
                  <div>
                    <span className="block font-medium">Monday - Saturday</span>
                    <span className="text-sm">8:00 AM - 8:00 PM</span>
                  </div>
                </li>
                <li className="flex items-center text-blue-200">
                  <Clock className="w-4 h-4 mr-3" />
                  <div>
                    <span className="block font-medium">Sunday</span>
                    <span className="text-sm">9:00 AM - 1:00 PM</span>
                  </div>
                </li>
                <li className="mt-6">
                  <div className="bg-blue-400/10 rounded-lg p-4 border border-blue-400/20">
                    <p className="text-blue-200 text-sm">
                      24/7 Emergency Services Available
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="pt-8 border-t border-blue-400/20">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              {/* Copyright */}
              <p className="text-blue-200 text-sm">
                &copy; {new Date().getFullYear()} MRI Care. All rights reserved.
              </p>

              {/* Social Links */}
              <div className="flex items-center gap-4">
                <a 
                  href="#" 
                  className="w-10 h-10 rounded-full bg-blue-400/10 flex items-center justify-center text-blue-200 hover:bg-blue-400/20 hover:text-white transition-colors"
                >
                  <Facebook className="w-5 h-5" />
                </a>
                <a 
                  href="#" 
                  className="w-10 h-10 rounded-full bg-blue-400/10 flex items-center justify-center text-blue-200 hover:bg-blue-400/20 hover:text-white transition-colors"
                >
                  <Instagram className="w-5 h-5" />
                </a>
                <a 
                  href="#" 
                  className="w-10 h-10 rounded-full bg-blue-400/10 flex items-center justify-center text-blue-200 hover:bg-blue-400/20 hover:text-white transition-colors"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};