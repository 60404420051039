import React from 'react';
import { ChevronRight, Brain, Heart, Activity, Phone, MapPin, IndianRupee, Shield, Clock, Award, CreditCard } from 'lucide-react';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  onLeadMagnetClick: () => void;
}

export const HeroSection: React.FC<HeroSectionProps> = ({ onLeadMagnetClick }) => {
  return (
    <section className="relative min-h-screen bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
      
      {/* MRI Scanner Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1516069677018-378515003a27?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80')] bg-no-repeat bg-cover bg-center opacity-10"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/80 via-blue-900/90 to-blue-900/95"></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-20">
        {/* Main Content */}
        <div className="text-center mb-16">
          {/* Location Badge */}
          <div className="inline-flex items-center px-4 py-2 bg-blue-800/30 rounded-full mb-8 backdrop-blur-sm border border-blue-400/20">
            <MapPin className="w-4 h-4 mr-2 text-blue-300" />
            <span className="text-blue-200 text-sm">Indore's Premier Imaging Center</span>
          </div>

          {/* Main Heading */}
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight max-w-4xl mx-auto">
            <span className="text-white">World-Class Medical Imaging</span>{' '}
            <br className="hidden lg:block" />
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-300 to-blue-400">
              Trusted Care in Indore
            </span>
          </h1>

          {/* Subheading */}
          <p className="text-xl md:text-2xl mb-12 text-blue-100 max-w-2xl mx-auto">
            MRI, CT Scan & PET Scan services with state-of-the-art technology and expert care
          </p>
        </div>

        {/* Service Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16 max-w-5xl mx-auto">
          {/* MRI Card */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20 transform hover:scale-105 transition-transform duration-300">
            <Brain className="w-12 h-12 text-blue-300 mb-4" />
            <h3 className="text-white text-2xl font-semibold mb-3">MRI Scan</h3>
            <p className="text-blue-200 mb-4">High-resolution 3D imaging for precise diagnosis</p>
            <div className="flex items-center text-white mb-4">
              <IndianRupee className="w-5 h-5 mr-1" />
              <span className="text-3xl font-bold">4,999</span>
              <span className="text-blue-200 ml-2">onwards</span>
            </div>
            <Link 
              to="/services/mri"
              className="inline-flex items-center text-blue-300 hover:text-blue-200"
            >
              Learn More <ChevronRight className="ml-1 w-4 h-4" />
            </Link>
          </div>

          {/* CT Scan Card */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20 transform hover:scale-105 transition-transform duration-300">
            <Activity className="w-12 h-12 text-blue-300 mb-4" />
            <h3 className="text-white text-2xl font-semibold mb-3">CT Scan</h3>
            <p className="text-blue-200 mb-4">Quick & detailed cross-sectional imaging</p>
            <div className="flex items-center text-white mb-4">
              <IndianRupee className="w-5 h-5 mr-1" />
              <span className="text-3xl font-bold">2,999</span>
              <span className="text-blue-200 ml-2">onwards</span>
            </div>
            <Link 
              to="/services/ct-scan"
              className="inline-flex items-center text-blue-300 hover:text-blue-200"
            >
              Learn More <ChevronRight className="ml-1 w-4 h-4" />
            </Link>
          </div>

          {/* PET Scan Card */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20 transform hover:scale-105 transition-transform duration-300">
            <Heart className="w-12 h-12 text-blue-300 mb-4" />
            <h3 className="text-white text-2xl font-semibold mb-3">PET Scan</h3>
            <p className="text-blue-200 mb-4">Advanced molecular imaging technology</p>
            <div className="flex items-center text-white mb-4">
              <IndianRupee className="w-5 h-5 mr-1" />
              <span className="text-3xl font-bold">14,999</span>
              <span className="text-blue-200 ml-2">onwards</span>
            </div>
            <Link 
              to="/services/pet-scan"
              className="inline-flex items-center text-blue-300 hover:text-blue-200"
            >
              Learn More <ChevronRight className="ml-1 w-4 h-4" />
            </Link>
          </div>
        </div>

        {/* USPs and CTA */}
        <div className="max-w-5xl mx-auto">
          {/* USPs */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
            <div className="flex flex-col items-center text-center">
              <Shield className="w-10 h-10 text-blue-300 mb-3" />
              <span className="text-white font-semibold">NABH Accredited</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <Award className="w-10 h-10 text-blue-300 mb-3" />
              <span className="text-white font-semibold">Expert Radiologists</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <Clock className="w-10 h-10 text-blue-300 mb-3" />
              <span className="text-white font-semibold">Same Day Reports</span>
            </div>
            <div className="flex flex-col items-center text-center">
              <CreditCard className="w-10 h-10 text-blue-300 mb-3" />
              <span className="text-white font-semibold">Insurance Accepted</span>
            </div>
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
            <Link
              to="/appointment"
              className="inline-flex items-center px-8 py-4 bg-white text-blue-900 rounded-full font-semibold hover:bg-blue-50 transition-colors shadow-lg shadow-blue-500/20"
            >
              Book Appointment
              <ChevronRight className="ml-2 w-5 h-5" />
            </Link>
            <a
              href="tel:+919876543210"
              className="inline-flex items-center px-8 py-4 border border-white/20 text-white rounded-full backdrop-blur-md hover:bg-white/10 transition-colors"
            >
              <Phone className="mr-2 w-5 h-5" />
              Call for Enquiry
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};