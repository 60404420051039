import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PatientPortal = () => {
  return (
    <>
      <Helmet>
        <title>Patient Portal | Advanced Medical Imaging Center</title>
        <meta name="description" content="Access your MRI scan results and medical records through our secure patient portal." />
      </Helmet>

      <div className="pt-24 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Patient Portal</h1>
            <p className="text-xl text-gray-600">
              Access your medical records, view scan results, and manage appointments
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-2xl shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Recent Scans</h2>
              {/* Add scan history component here */}
            </div>

            <div className="bg-white p-8 rounded-2xl shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Upcoming Appointments</h2>
              {/* Add appointments component here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};