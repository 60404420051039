import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Brain, Activity, Heart, Check, Clock, Shield, Award, ChevronRight } from 'lucide-react';

const services = {
  'brain-mri': {
    title: 'Brain MRI Scan',
    description: 'High-resolution neurological imaging for precise diagnosis',
    icon: Brain,
    benefits: [
      'Detailed 3D visualization of brain structures',
      'Early detection of neurological conditions',
      'Non-invasive and radiation-free',
      'Same-day report availability'
    ],
    features: [
      {
        title: 'Advanced Technology',
        description: '3-Tesla MRI scanner for superior image quality'
      },
      {
        title: 'Expert Analysis',
        description: 'Interpretation by experienced neuroradiologists'
      },
      {
        title: 'Quick Process',
        description: '30-45 minutes scanning time'
      },
      {
        title: 'Comprehensive Report',
        description: 'Detailed findings with expert recommendations'
      }
    ],
    price: '4,999',
    preparations: [
      'No metal objects or electronic devices',
      'Inform us about any medical implants',
      'Wear comfortable clothing',
      'Arrive 15 minutes before appointment'
    ]
  },
  'cardiac-mri': {
    title: 'Cardiac MRI Scan',
    description: 'Detailed imaging of heart structure and function',
    icon: Heart,
    benefits: [
      'Precise evaluation of heart chambers',
      'Assessment of blood flow and function',
      'Detection of cardiovascular conditions',
      'Safe and non-invasive procedure'
    ],
    features: [
      {
        title: 'Cardiac Expertise',
        description: 'Specialized cardiac imaging protocols'
      },
      {
        title: 'Motion Correction',
        description: 'Advanced technology for clear cardiac images'
      },
      {
        title: 'Comprehensive Study',
        description: '45-60 minutes detailed examination'
      },
      {
        title: 'Expert Analysis',
        description: 'Review by cardiac imaging specialists'
      }
    ],
    price: '6,999',
    preparations: [
      'Fasting for 4-6 hours before scan',
      'Bring previous cardiac reports',
      'Continue medications as prescribed',
      'Wear comfortable clothing'
    ]
  }
};

export const ServicePage = () => {
  const { service } = useParams();
  const serviceData = services[service as keyof typeof services];
  const Icon = serviceData?.icon;

  if (!serviceData) return <div>Service not found</div>;

  return (
    <>
      <Helmet>
        <title>{serviceData.title} | Advanced Medical Imaging Center</title>
        <meta name="description" content={serviceData.description} />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        {/* Hero Section */}
        <div className="relative pt-32 pb-20 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-transparent" />
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <div className="inline-flex items-center justify-center w-20 h-20 mb-8 rounded-full bg-blue-100">
                <Icon className="w-10 h-10 text-blue-600" />
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">{serviceData.title}</h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">{serviceData.description}</p>
              <div className="flex items-center justify-center gap-4">
                <button className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                  Book Appointment
                  <ChevronRight className="ml-2 w-5 h-5" />
                </button>
                <div className="flex items-center text-2xl font-bold text-gray-900">
                  ₹{serviceData.price}
                  <span className="text-sm font-normal text-gray-500 ml-1">onwards</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24">
          <div className="grid md:grid-cols-2 gap-12">
            {/* Left Column */}
            <div className="space-y-12">
              {/* Benefits */}
              <div className="bg-white rounded-2xl shadow-lg p-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Key Benefits</h2>
                <div className="space-y-4">
                  {serviceData.benefits.map((benefit, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center">
                        <Check className="w-4 h-4 text-blue-600" />
                      </div>
                      <p className="text-gray-700">{benefit}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Features */}
              <div className="grid grid-cols-2 gap-6">
                {serviceData.features.map((feature, index) => (
                  <div key={index} className="bg-white rounded-xl shadow-md p-6">
                    <h3 className="font-semibold text-gray-900 mb-2">{feature.title}</h3>
                    <p className="text-gray-600 text-sm">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-12">
              {/* Preparation Guidelines */}
              <div className="bg-gradient-to-br from-blue-600 to-blue-700 rounded-2xl shadow-lg p-8 text-white">
                <h2 className="text-2xl font-bold mb-6">Preparation Guidelines</h2>
                <div className="space-y-4">
                  {serviceData.preparations.map((prep, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-white/20 flex items-center justify-center">
                        <Check className="w-4 h-4 text-white" />
                      </div>
                      <p className="text-blue-50">{prep}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* USPs */}
              <div className="grid grid-cols-2 gap-6">
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <Shield className="w-10 h-10 text-blue-600 mb-3" />
                  <h3 className="font-semibold text-gray-900">NABH Accredited</h3>
                  <p className="text-gray-600 text-sm mt-1">Quality assured facility</p>
                </div>
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <Award className="w-10 h-10 text-blue-600 mb-3" />
                  <h3 className="font-semibold text-gray-900">Expert Team</h3>
                  <p className="text-gray-600 text-sm mt-1">Experienced radiologists</p>
                </div>
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <Clock className="w-10 h-10 text-blue-600 mb-3" />
                  <h3 className="font-semibold text-gray-900">Quick Results</h3>
                  <p className="text-gray-600 text-sm mt-1">Same day reports</p>
                </div>
                <div className="bg-white rounded-xl shadow-md p-6 flex flex-col items-center text-center">
                  <Activity className="w-10 h-10 text-blue-600 mb-3" />
                  <h3 className="font-semibold text-gray-900">Latest Technology</h3>
                  <p className="text-gray-600 text-sm mt-1">3T MRI Scanner</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};