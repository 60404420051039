import React, { useState } from 'react';
import { ChevronDown, Plus, Minus, HelpCircle } from 'lucide-react';

export const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "What should I expect during an MRI scan?",
      answer: "During an MRI scan, you'll lie still on a table that slides into the scanner. The procedure is painless and typically takes 30-60 minutes. You'll hear some noise from the machine, but we provide earplugs or headphones for your comfort."
    },
    {
      question: "How do I prepare for my MRI scan?",
      answer: "Remove all metal objects, including jewelry and electronic devices. Wear comfortable clothing without metal fasteners. Inform us of any medical conditions, implants, or if you're pregnant. Some scans may require fasting or special preparation."
    },
    {
      question: "Is an MRI scan safe?",
      answer: "Yes, MRI scans are very safe. They use non-ionizing radiation (magnetic fields and radio waves) instead of X-rays. However, people with certain metal implants or devices should inform us beforehand."
    },
    {
      question: "How long does it take to get MRI results?",
      answer: "Results are typically available within 24-48 hours. Our radiologists analyze the images and send a detailed report to your referring physician, who will discuss the findings with you."
    },
    {
      question: "Do you accept insurance?",
      answer: "Yes, we accept most major insurance plans. Please contact our office with your insurance information, and we'll verify your coverage and explain any out-of-pocket costs."
    },
    {
      question: "What if I'm claustrophobic?",
      answer: "We understand that some patients feel anxious about MRI scans. We offer open MRI options and can provide mild sedation if necessary. Our experienced staff will help you feel comfortable throughout the procedure."
    }
  ];

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white" />
      <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-20">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mb-6">
            <HelpCircle className="w-8 h-8 text-blue-600" />
          </div>
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-gray-900">Common Questions About</span>{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
              MRI Scans
            </span>
          </h2>
          <p className="text-xl text-gray-600">
            Get answers to frequently asked questions about our MRI services
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className={`
                group relative bg-white/80 backdrop-blur-sm rounded-2xl shadow-sm
                border border-blue-100 transition-all duration-200
                ${openIndex === index ? 'shadow-lg ring-1 ring-blue-200' : 'hover:shadow-md'}
              `}
            >
              {/* Hover Effect */}
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-5 blur transition-opacity" />
              
              <button
                className="relative w-full px-8 py-6 flex items-center justify-between gap-4"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="text-lg font-medium text-gray-900 text-left">
                  {faq.question}
                </span>
                <div className={`
                  flex items-center justify-center w-8 h-8 
                  rounded-full transition-colors duration-200
                  ${openIndex === index 
                    ? 'bg-blue-100 text-blue-600' 
                    : 'bg-gray-100 text-gray-500 group-hover:bg-blue-50 group-hover:text-blue-500'
                  }
                `}>
                  {openIndex === index ? (
                    <Minus className="w-4 h-4" />
                  ) : (
                    <Plus className="w-4 h-4" />
                  )}
                </div>
              </button>
              <div
                className={`
                  overflow-hidden transition-all duration-300 ease-in-out
                  ${openIndex === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}
                `}
              >
                <div className="relative px-8 pb-6">
                  <div className="h-px bg-gradient-to-r from-blue-100 via-blue-200 to-blue-100 mb-6" />
                  <p className="text-gray-600 leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Help Section */}
        <div className="mt-16 text-center">
          <p className="text-gray-600 mb-4">Still have questions?</p>
          <a
            href="/contact"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
          >
            Contact Our Team
            <ChevronDown className="ml-2 w-4 h-4" />
          </a>
        </div>
      </div>
    </section>
  );
};
