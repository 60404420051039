import { Helmet } from 'react-helmet-async';
import { HeroSection } from '../components/HeroSection';
import { ServiceCard } from '../components/ServiceCard';
import { StatsSection } from '../components/StatsSection';
import { CTASection } from '../components/CTASection';
import { TestimonialSection } from '../components/TestimonialSection';
import { FAQSection } from '../components/FAQSection';
import { Brain, Activity, Stethoscope, ChevronRight, Clock, Shield, Building2, Users } from 'lucide-react';

interface HomePageProps {
  onLeadMagnetClick: () => void;
}

export const HomePage = ({ onLeadMagnetClick }: HomePageProps) => {
  const services = [
    {
      icon: Brain,
      title: 'Brain MRI',
      description: 'Advanced neurological imaging for precise diagnosis of brain conditions, tumors, and disorders.',
      link: '/services/brain-mri',
      features: ['3D Brain Mapping', 'Tumor Detection', 'Stroke Assessment'],
      price: '₹4,999 onwards'
    },
    {
      icon: Activity,
      title: 'Full Body MRI',
      description: 'Comprehensive full-body scanning for thorough health assessment and preventive care.',
      link: '/services/full-body-mri',
      features: ['Complete Health Screening', 'Early Disease Detection', 'Detailed Report'],
      price: '₹14,999 onwards'
    },
    {
      icon: Stethoscope,
      title: 'Cardiac MRI',
      description: 'Specialized cardiac imaging for detailed heart structure and function analysis.',
      link: '/services/cardiac-mri',
      features: ['Heart Function Analysis', 'Blood Flow Study', 'Structural Assessment'],
      price: '₹6,999 onwards'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Advanced MRI Services | Professional Medical Imaging Center</title>
        <meta 
          name="description" 
          content="Experience state-of-the-art MRI scanning services with our advanced medical imaging center. Offering brain, cardiac, and full-body MRI scans with expert interpretation." 
        />
        <meta 
          name="keywords" 
          content="MRI scan, brain MRI, cardiac MRI, full body MRI, medical imaging, diagnostic imaging" 
        />
      </Helmet>

      <HeroSection onLeadMagnetClick={onLeadMagnetClick} />
      
      <section id="services" className="relative py-20 overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white" />
        <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-gray-900">Advanced</span>{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
                MRI Services
              </span>
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              State-of-the-art diagnostic imaging with our 3-Tesla MRI scanner for exceptional clarity and precision
            </p>
          </div>

          {/* Service Cards */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {services.map((service, index) => (
              <div
                key={service.title}
                className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300"
              >
                {/* Gradient Blob */}
                <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
                
                <div className="relative">
                  {/* Icon */}
                  <div className="w-16 h-16 bg-gradient-to-br from-blue-600 to-blue-400 rounded-2xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                    <service.icon className="w-8 h-8 text-white" />
                  </div>

                  {/* Content */}
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>

                  {/* Features */}
                  <ul className="space-y-3 mb-6">
                    {service.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                          <div className="w-2 h-2 rounded-full bg-blue-600" />
                        </div>
                        {feature}
                      </li>
                    ))}
                  </ul>

                  {/* Price and CTA */}
                  <div className="flex items-center justify-between">
                    <div className="text-gray-900">
                      <span className="text-2xl font-bold">{service.price}</span>
                    </div>
                    <a
                      href={service.link}
                      className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                    >
                      Learn More
                      <ChevronRight className="ml-2 w-4 h-4" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

         
        </div>
      </section>

      <section id="patient-journey" className="relative py-20 overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-gradient-to-b from-white to-gray-50" />
        <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-gray-900">Your</span>{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
                Patient Journey
              </span>
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Understanding what to expect during your MRI examination
            </p>
          </div>

          {/* Journey Steps */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {/* Step 1: Preparation */}
            <div className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
              <div className="relative">
                <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-2xl font-bold text-blue-600">1</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">Preparation</h3>
                <ul className="space-y-3">
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Remove metal objects and jewelry</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Wear comfortable clothing</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Follow fasting instructions if required</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Step 2: Arrival */}
            <div className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
              <div className="relative">
                <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-2xl font-bold text-blue-600">2</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">Arrival</h3>
                <ul className="space-y-3">
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Arrive 15 minutes early</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Complete registration process</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Review safety questionnaire</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Step 3: During Scan */}
            <div className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
              <div className="relative">
                <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-2xl font-bold text-blue-600">3</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">During Scan</h3>
                <ul className="space-y-3">
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Lie still on scanning table</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Communicate via intercom</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>30-60 minutes duration</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Step 4: After Scan */}
            <div className="group relative bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100 hover:shadow-xl transition-all duration-300">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl opacity-0 group-hover:opacity-10 blur transition-opacity" />
              <div className="relative">
                <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                  <span className="text-2xl font-bold text-blue-600">4</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">After Scan</h3>
                <ul className="space-y-3">
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Resume normal activities</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Results within 24-48 hours</span>
                  </li>
                  <li className="flex items-start text-gray-600">
                    <div className="w-5 h-5 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center mt-1 mr-3">
                      <div className="w-2 h-2 rounded-full bg-blue-600" />
                    </div>
                    <span>Doctor consultation</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="text-center">
            <a
              href="/patient-guide"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
            >
              Download Patient Guide
              <ChevronRight className="ml-2 w-4 h-4" />
            </a>
          </div>
        </div>
      </section>

      <section id="stats">
        <StatsSection />
      </section>
      
      <section id="testimonials">
        <TestimonialSection />
      </section>
      
      <section id="faq">
        <FAQSection />
      </section>
      
      <CTASection onLeadMagnetClick={onLeadMagnetClick} />
    </>
  );
};