import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Calendar, Clock, FileText, MapPin, Phone, Shield, AlertCircle } from 'lucide-react';
import mriLogo from '/src/assets/mriscan-logo-transparent.png';

export const AppointmentPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    scanType: 'brain-mri',
    preferredDate: '',
    preferredTime: '',
    notes: '',
    insurance: '',
    referral: false
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const scanTypes = [
    {
      id: 'brain-mri',
      name: 'Brain MRI',
      description: 'Detailed imaging of brain structure and function',
      price: '₹4,999 onwards'
    },
    {
      id: 'cardiac-mri',
      name: 'Cardiac MRI',
      description: 'Comprehensive heart structure analysis',
      price: '₹6,999 onwards'
    },
    {
      id: 'full-body-mri',
      name: 'Full Body MRI',
      description: 'Complete body screening and assessment',
      price: '₹14,999 onwards'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Schedule MRI Appointment | MRI Care Center</title>
        <meta name="description" content="Schedule your MRI scan appointment with our state-of-the-art imaging center. Expert care at affordable prices." />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 bg-[url('/pattern.svg')] opacity-5" />
        
        <div className="relative pt-32 pb-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* Header Section */}
            <div className="text-center mb-12">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="text-gray-900">Schedule Your</span>{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-400">
                  MRI Scan
                </span>
              </h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                Book your appointment at our state-of-the-art facility. Expert care and advanced technology at affordable prices.
              </p>
            </div>

            <div className="grid lg:grid-cols-3 gap-8">
              {/* Form Section */}
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit} className="bg-white/80 backdrop-blur-lg rounded-2xl p-8 shadow-lg border border-blue-100">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    {/* Personal Information */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Full Name
                      </label>
                      <input
                        type="text"
                        required
                        className="w-full px-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        placeholder="Enter your full name"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        required
                        className="w-full px-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Phone Number
                      </label>
                      <div className="relative">
                        <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                          type="tel"
                          required
                          className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                          placeholder="Enter your phone number"
                          value={formData.phone}
                          onChange={(e) => setFormData({...formData, phone: e.target.value})}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Insurance Provider (Optional)
                      </label>
                      <div className="relative">
                        <Shield className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                          type="text"
                          className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                          placeholder="Enter insurance provider"
                          value={formData.insurance}
                          onChange={(e) => setFormData({...formData, insurance: e.target.value})}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Scan Selection */}
                  <div className="mb-8">
                    <label className="block text-sm font-medium text-gray-700 mb-4">
                      Select MRI Scan Type
                    </label>
                    <div className="grid gap-4">
                      {scanTypes.map((scan) => (
                        <label
                          key={scan.id}
                          className={`relative flex items-center p-4 cursor-pointer rounded-lg border transition-all ${
                            formData.scanType === scan.id
                              ? 'border-blue-500 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-200'
                          }`}
                        >
                          <input
                            type="radio"
                            name="scanType"
                            value={scan.id}
                            checked={formData.scanType === scan.id}
                            onChange={(e) => setFormData({...formData, scanType: e.target.value})}
                            className="sr-only"
                          />
                          <div className="flex-1">
                            <div className="flex justify-between">
                              <div>
                                <h3 className="text-sm font-semibold text-gray-900">{scan.name}</h3>
                                <p className="text-sm text-gray-500">{scan.description}</p>
                              </div>
                              <div className="text-blue-600 font-semibold">{scan.price}</div>
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Date and Time Selection */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Preferred Date
                      </label>
                      <div className="relative">
                        <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                          type="date"
                          required
                          className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                          value={formData.preferredDate}
                          onChange={(e) => setFormData({...formData, preferredDate: e.target.value})}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Preferred Time
                      </label>
                      <div className="relative">
                        <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <select
                          required
                          className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                          value={formData.preferredTime}
                          onChange={(e) => setFormData({...formData, preferredTime: e.target.value})}
                        >
                          <option value="">Select time slot</option>
                          <option value="09:00">09:00 AM</option>
                          <option value="10:00">10:00 AM</option>
                          <option value="11:00">11:00 AM</option>
                          <option value="12:00">12:00 PM</option>
                          <option value="14:00">02:00 PM</option>
                          <option value="15:00">03:00 PM</option>
                          <option value="16:00">04:00 PM</option>
                          <option value="17:00">05:00 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* Additional Notes */}
                  <div className="mb-8">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Additional Notes
                    </label>
                    <div className="relative">
                      <FileText className="absolute left-3 top-3 text-gray-400 w-5 h-5" />
                      <textarea
                        className="w-full pl-12 pr-4 py-3 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        rows={4}
                        placeholder="Any specific requirements or medical conditions we should know about"
                        value={formData.notes}
                        onChange={(e) => setFormData({...formData, notes: e.target.value})}
                      />
                    </div>
                  </div>

                  {/* Doctor Referral Checkbox */}
                  <div className="mb-8">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={formData.referral}
                        onChange={(e) => setFormData({...formData, referral: e.target.checked})}
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        I have a doctor's referral for this MRI scan
                      </span>
                    </label>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg font-semibold hover:from-blue-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transform transition-all shadow-lg"
                  >
                    Schedule Appointment
                  </button>
                </form>
              </div>

              {/* Information Section */}
              <div className="space-y-6">
                {/* Location Card */}
                <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 shadow-lg border border-blue-100">
                  <div className="flex items-center mb-4">
                    <MapPin className="w-5 h-5 text-blue-600 mr-2" />
                    <h3 className="text-lg font-semibold text-gray-900">Location</h3>
                  </div>
                  <p className="text-gray-600 mb-4">
                    123 Medical Center Road,<br />
                    Mumbai, Maharashtra 400001
                  </p>
                  <a
                    href="#"
                    className="text-blue-600 hover:text-blue-700 font-medium flex items-center"
                  >
                    Get Directions
                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                </div>

                {/* Important Information */}
                <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 shadow-lg border border-blue-100">
                  <div className="flex items-center mb-4">
                    <AlertCircle className="w-5 h-5 text-blue-600 mr-2" />
                    <h3 className="text-lg font-semibold text-gray-900">Important Information</h3>
                  </div>
                  <ul className="space-y-3 text-gray-600">
                    <li className="flex items-start">
                      <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2 flex-shrink-0" />
                      <span>Please arrive 15 minutes before your appointment</span>
                    </li>
                    <li className="flex items-start">
                      <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2 flex-shrink-0" />
                      <span>Bring any previous medical reports or scans</span>
                    </li>
                    <li className="flex items-start">
                      <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2 flex-shrink-0" />
                      <span>Remove all metal objects before the scan</span>
                    </li>
                    <li className="flex items-start">
                      <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2 flex-shrink-0" />
                      <span>Wear comfortable clothing without metal parts</span>
                    </li>
                  </ul>
                </div>

                {/* Contact Support */}
                <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 shadow-lg border border-blue-100">
                  <div className="flex items-center mb-4">
                    <Phone className="w-5 h-5 text-blue-600 mr-2" />
                    <h3 className="text-lg font-semibold text-gray-900">Need Help?</h3>
                  </div>
                  <p className="text-gray-600 mb-4">
                    Our support team is available 24/7 to assist you with your booking.
                  </p>
                  <a
                    href="tel:+919876543210"
                    className="text-blue-600 hover:text-blue-700 font-medium flex items-center"
                  >
                    +91 98765 43210
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};